import React, { createContext, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { BaseContext } from './BaseContext';

const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { apiUrl, apiToken, useStaticSources } = useContext(BaseContext);
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState({});

  useEffect(() => {
    if (!useStaticSources) {
      // Effettua la chiamata all'API di Strapi per ottenere i dati dell'azienda
      axios.get(`${apiUrl}/api/company?locale=${i18n.language}&populate=deep,5`, {
        headers: {
          'Authorization': `Bearer ${apiToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setCompanyData(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Errore durante il recupero dei dati dell\'azienda:', error);
          setLoading(false);
        });
    } else {
      setCompanyData(t('boostCache.companyData', { returnObjects: true }));
      setLoading(false);
    }
  }, [i18n.language]);

  return (
    <CompanyContext.Provider value={{ companyData }}>
      {!loading ? children : <div></div>}
    </CompanyContext.Provider>
  );
};

export { CompanyContext, CompanyProvider };

import React from 'react';

const IconSvg = ({ name, vb, myClass, size, myStyle }) => {
    return (
        <svg style={myStyle} className={myClass} viewBox={vb}>
            <use xlinkHref={`#${name}`} width="100%" height="100%"></use>
        </svg>
    )
}

export default IconSvg;
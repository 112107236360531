import { useEffect, useState } from 'react';

const IubendaLoader = () => {
    const [isHydrated, setHydrated] = useState(false);

    useEffect(() => {
        // Questo useEffect viene eseguito solo una volta, quando il componente viene montato
        setHydrated(true); // Imposta lo stato su true per segnalare che il DOM è stato idratato
    }, []);

    useEffect(() => {
        if (isHydrated) {
            // Controlla se gli script sono già stati caricati
            if (window.iubendaLoaded) {
                return;
            }
            window.iubendaLoaded = true;

            // Assicurati che lo script non sia già stato caricato
            if (!document.getElementById('iubenda-script')) {
                // Caricamento dello script iubenda_cs.js
                const scriptCS = document.createElement('script');
                scriptCS.id = 'iubenda-cs-script-test';
                scriptCS.src = 'https://cdn.iubenda.com/cs/iubenda_cs.js';
                scriptCS.async = true;
                document.body.appendChild(scriptCS);

                // Caricamento dello script iubenda.js
                const scriptMain = document.createElement('script');
                scriptMain.id = 'iubenda-main-script-test';
                scriptMain.src = 'https://cdn.iubenda.com/iubenda.js';
                scriptMain.async = true;
                document.body.appendChild(scriptMain);
            }
        }
    }, [isHydrated]); // Questo useEffect dipende dallo stato isHydrated

    return null;  // Nessun output visivo da questo componente
};

export default IubendaLoader;

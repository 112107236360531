import { config, useStaticSources } from './Constants'
import React, { createContext, useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Rellax from "rellax";
import AOS from "aos";
import 'lazysizes';


const BaseContext = createContext();

const BaseProvider = ({ children }) => {
  const [apiUrl, setApiUrl] = useState(config.url.API_URL);
  const [apiToken, setApiToken] = useState('690996c89fa7c1c815912a7aa9d21a9038c18d674c01e5ab74d1e63b1cabb0e562aa5b3315b732d1e8f8270a95e47b190ddef91be008c4a3bb58e78652c8beac17cbaf1835cc2ef88335257c896027943da914f3dffd91b479fa349615df2c759164f41f95ccae4c4d6c33e92cee8aa91dc3de80c998aef1341ea09cfd92b176');
  const [language, setLanguage] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  //const envApiToken = process.env.REACT_APP_STRAPI_API_TOKEN;
  //const envApiToken = "690996c89fa7c1c815912a7aa9d21a9038c18d674c01e5ab74d1e63b1cabb0e562aa5b3315b732d1e8f8270a95e47b190ddef91be008c4a3bb58e78652c8beac17cbaf1835cc2ef88335257c896027943da914f3dffd91b479fa349615df2c759164f41f95ccae4c4d6c33e92cee8aa91dc3de80c998aef1341ea09cfd92b176"

  const handleLazyBeforeUnveil = (e) => {
    // Esegui il tuo codice JavaScript quando un elemento sta per essere svelato
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
    //console.log('Elemento in arrivo:', e.target);
  };

  const handleOnUnload = (e) => {
    //console.log("unload done")
    window.scrollTo(0, 0);
  }

  const changeLanguage = (lang, isFirstSet) => {
    setLanguage(lang);
    i18n.changeLanguage(lang)

    if (!isFirstSet) {
      //const regex = /\/(it|en)\/?/gmi;
      const regex = /\/(\D{2})\/?/gmi;
      var result = "/";
      var test = location.pathname;

      if (test !== "/") {
        result = test.replace(regex, `/${lang}/`).replace(/\/$/, "");

        if (result === "/it") {
          result = "/";
        }
      } else {
        result = `/${lang}`;
      }

      navigate(result); // Aggiorna l'URL
    }
  };

  useLayoutEffect(() => {
    //console.log(process.env)

    AOS.init();

    if (document.querySelector('.rellax')) {
      new Rellax('.rellax');
    }

    // Cleanup
    return () => {
      // Codice di cleanup (se necessario)
    };
  }, []);

  useEffect(() => {
    let languageFromUrl = 'it';
    if (location.pathname.length > 1) {
      languageFromUrl = location.pathname.split('/')[1];
    }

    //console.log('out -> ' + language)
    if (languageFromUrl && languageFromUrl !== language) {
      //console.log('in -> ' + languageFromUrl)
      changeLanguage(languageFromUrl, language === '');
    }

    setIsInitialized(true);

    document.addEventListener('lazybeforeunveil', handleLazyBeforeUnveil);
    //document.addEventListener('load', handleOnLoad);
    window.addEventListener('unload', handleOnUnload);

    return () => {
      document.removeEventListener('lazybeforeunveil', handleLazyBeforeUnveil);
      //document.removeEventListener('load', handleOnLoad);
      window.removeEventListener('unload', handleOnUnload);
    };
  }, []);

  const contextValue = {
    apiUrl,
    apiToken,
    setApiUrl,
    useStaticSources,
    changeLanguage,
    isInitialized
  };

  return (
    <BaseContext.Provider value={contextValue}>
      {isInitialized ? children : <div></div>}
    </BaseContext.Provider>
  );
};

export { BaseContext, BaseProvider };

// // import axios from 'axios';
// // import { setupCache } from 'axios-cache-adapter';

// // // Crea un'istanza di cache
// // const cache = setupCache({
// //   maxAge: 15 * 60 * 1000 // tempo di vita della cache in millisecondi (es. 15 minuti)
// // });

// // // Crea un'istanza di axios con la configurazione di cache
// // const api = axios.create({
// //   adapter: cache.adapter
// // });

// // // Utilizza l'istanza `api` per fare chiamate API con cache
// // api.get('https://someapi.com/data').then(response => {
// //   console.log(response.data);
// // });

// // // Esempio: Configurazione di cache per una specifica richiesta
// // api.get('https://someapi.com/other-data', {
// //   cache: {
// //     maxAge: 5 * 60 * 1000 // cache specifica per questa chiamata (5 minuti)
// //   }
// // });

// // // Invalida la cache per una specifica URL
// // cache.store.removeItem('https://someapi.com/data');

// // // Invalida tutta la cache
// // cache.store.clear();

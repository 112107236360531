import React, { useEffect, useRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../context/CompanyContext';
import { BaseContext } from '../context/BaseContext';

import { Link, useMatch, useLocation } from 'react-router-dom';
import IconSvg from './IconSvg';

const Header = () => {
  const { changeLanguage } = useContext(BaseContext);
  const { companyData } = useContext(CompanyContext);

  const { t, i18n } = useTranslation();

  const [navbarOpen, setNavbarOpen] = useState(false);
  const ref = useRef();

  const match = useMatch(`/${i18n.language}`);
  const matchIT = useMatch(`/`);

  const location = useLocation();

  useEffect(() => {
    // Estrai la parte dell'URL che desideri utilizzare per determinare la classe
    const path = location.pathname;

    // Definisci la mappa delle classi basate sulla location
    const classMap = {
      '/': 'body--home',
      '/en': 'body--home',
      '/menu': 'body--menu',
      '/en/menu': 'body--menu',
      '/contact': 'body--contact',
      '/en/contact': 'body--contact',
      '/gallery': 'body--gallery',
      '/en/gallery': 'body--gallery',
      // Aggiungi altre corrispondenze di URL e classi qui
    };

    // Ottieni tutte le classi attualmente presenti nel corpo
    const bodyClasses = Array.from(document.body.classList);

    // Rimuovi solo le classi presenti in classMap
    for (const className of bodyClasses) {
      if (Object.values(classMap).includes(className)) {
        document.body.classList.remove(className);
      }
    }

    // Aggiungi la classe corrispondente al pathname
    for (const [key, className] of Object.entries(classMap)) {
      if (path.indexOf(key) > -1 && key !== '/') {
        document.body.classList.add(className);
      }
    }

    if (path === '/') {
      document.body.classList.add('body--home');
    }

  }, [location.pathname]);

  useEffect(() => {
    const handler = (event) => {
      if (
        navbarOpen &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
    };
  }, [navbarOpen]);

  if (!companyData || !companyData.data) {
    return <div></div>;
  }

  const company = companyData.data.attributes;
  let isFull = match || matchIT;

  return (
    <header className={!isFull ? 'header--compact' : null}>
      <nav ref={ref} className={`navbar ${navbarOpen ? ' navbar--active' : ''}`}>
        <div className="sidebar__left ty-aligned-vertical">
          <span className="ty-aligned-vertical">
            <button
              className="hamburgher__wrapper"
              onClick={() => setNavbarOpen((prev) => !prev)}>
              <span className={`hamburgher${navbarOpen ? ' hamburgher--active' : ''}`}>
                <span className="hamburgher__row"></span>
                <span className="hamburgher__row"></span>
                <span className="hamburgher__row"></span>
                <span className="hamburgher__row"></span>
              </span>

              <span className="size-m"><span className="hamburgher__label ty-header ty-header--tiny -d-ml--sm">Menu</span></span>
            </button>

            <a className="size-m -d-ml--sm" href={`${company.LinkMaps}`} target="_blank" rel="noreferrer">
              <span className="ty-aligned-vertical ty-header ty-header--tiny ty-dubbed">
                <IconSvg myClass="icon icon--big -d-mr--xs" name="icon_pin"></IconSvg>
                <span className="size-m"><b>{company.Citta}</b> {company.Indirizzo}</span>
              </span>
            </a>

            <div className="ty-aligned-vertical -d-ml--sm size-sm-only">
              {i18n.language === 'it' && <button className={"button button--empty"} onClick={() => changeLanguage('en')} style={{ border: 0, background: "transparent", color: "white" }}><img className="icon icon--big lazyload" data-src="/dist/assets/images/eng.svg" title="English Website" alt="English Website" /></button>}
              {i18n.language === 'en' && <button className={"button button--empty"} onClick={() => changeLanguage('it')} style={{ border: 0, background: "transparent", color: "white" }}><img className="icon icon--big lazyload" data-src="/dist/assets/images/ita.svg" title="Versione Italiana" alt="Versione Italiana" /></button>}
            </div>
          </span>
        </div>

        <Link to={`${i18n.language === 'it' ? "/" : ("/" + i18n.language)}`}><img className="lazylogo lazyload" data-src="/dist/assets/images/logo.png" title="Victoria Station" alt="Victoria Station" /></Link>

        <div className="sidebar__right">
          <div className="ty-aligned-vertical">
            <span className="size-inline-m">
              <a className="ty-aligned-vertical ty-header ty-header--tiny ty-dubbed" href={`mailto:${company.Prenotazione}`} target="_blank" rel="noreferrer">
                <IconSvg myClass="icon icon--big" name="icon_mail"></IconSvg>
                <span className="size-xl -d-ml--sm">{company.Prenotazione}</span>
                <span className="size-l">
                  <span className="size-l -d-mr--lg"></span>
                </span>
                <span className="size-ml-only">
                  <span className="size-ml-only -d-ml--sm"></span>
                </span>
              </a>
            </span>
            <a className="ty-aligned-vertical ty-header ty-header--tiny ty-dubbed size-inline-m" href={`https://api.whatsapp.com/send?phone=${company.Telefono.replace(/\s/g, '').replace(/\+/g, '')}`} target="_blank" rel="noreferrer">
              <IconSvg myClass="icon icon--big" style={{ height: "22px", width: "22px" }} name="icon_whatsapp"></IconSvg>
              <span className="size-ml-only">
                <span className="size-ml-only -d-ml--md"></span>
              </span>
              <span className="size-l">
                <span className="size-l -d-ml--lg"></span>
              </span>
            </a>
            <a className="ty-aligned-vertical ty-header ty-header--tiny ty-dubbed -d-mr--sm" href={`tel:${company.Telefono.replace(/\s/g, '')}`} target="_blank" rel="noreferrer">
              <IconSvg myClass="icon icon--big" name="icon_phone"></IconSvg>
              <span className="size-l">
                <span className="size-l -d-ml--sm">{company.Telefono}</span>
              </span>
            </a>
            <div className="size-l">
              {i18n.language === 'it' && <button className={"button button--empty -d-mr--xs"} onClick={() => changeLanguage('en')} style={{ border: 0, background: "transparent", color: "white" }}><img className="icon icon--big lazyload" data-src="/dist/assets/images/eng.svg" title="English Website" alt="English Website" /></button>}
              {i18n.language === 'en' && <button className={"button button--empty -d-mr--xs"} onClick={() => changeLanguage('it')} style={{ border: 0, background: "transparent", color: "white" }}><img className="icon icon--big lazyload" data-src="/dist/assets/images/ita.svg" title="Versione Italiana" alt="Versione Italiana" /></button>}
            </div>
          </div>
        </div>

        <div className="menu__wrapper">
          <div className="menu__bg"></div>
          <ul className="menu">
            <li>
              <Link className="ty-header ty-header--compact ty-secondary" to={`${i18n.language === 'it' ? "/" : ("/" + i18n.language)}`} onClick={() => setNavbarOpen(false)}>{t('nav.home')}</Link>
            </li>
            <li>
              <Link className="ty-header ty-header--compact ty-secondary" to={`/${i18n.language}/anniversary`} onClick={() => setNavbarOpen(false)}>{t('nav.anniversary')}</Link>
            </li>
            <li>
              <Link className="ty-header ty-header--compact ty-secondary" to={`/${i18n.language}/menu`} onClick={() => setNavbarOpen(false)}>{t('nav.menu')}</Link>
            </li>
            <li>
              <Link className="ty-header ty-header--compact ty-secondary" to={`/${i18n.language}/gallery`} onClick={() => setNavbarOpen(false)}>{t('nav.gallery')}</Link>
            </li>
            <li>
              <Link className="ty-header ty-header--compact ty-secondary" to={`/${i18n.language}/contact`} onClick={() => setNavbarOpen(false)}>{t('nav.contact')}</Link>
            </li>
          </ul>
        </div>
      </nav>

      <Link to={`${i18n.language === 'it' ? "/" : ("/" + i18n.language)}`}><img className="logo lazyload" data-src="/dist/assets/images/logo.png" title="Victoria Station" alt="Victoria Station" /></Link>
    </header>
  );
};

export default Header;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/app.css';
import App from './App';
import IubendaLoader from './components/IubendaLoader';
import GTMLoader from './components/GTMLoader';

const gtmId = 'GTM-P87JWK9';
const isSnap = navigator && navigator.userAgent === 'ReactSnap';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {!isSnap ? <IubendaLoader /> : null}
    {!isSnap ? <GTMLoader gtmId={gtmId} /> : null}
  </React.StrictMode>
);

// Constants.js
const prod = {
    url: {
        API_URL: "https://admin.victoriastation.it"
    }
};

const dev = {
    url: {
        API_URL: "https://admin.victoriastation.it"//"http://localhost:1337"
    }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
export const useStaticSources = process.env.NODE_ENV === "development" ? false : false;